// button, html [type="button"], [type="reset"], [type="submit"] {
//   -webkit-appearance: none !important;
// }
.disableHover:hover {
  background-color: inherit !important;
}

.ant-input-suffix {
  right: 0 !important;
}

.tags-scrolling-wrapper-flexbox {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -webkit-transform: translateZ(0px);
  -webkit-transform: translate3d(0, 0, 0);
  -webkit-perspective: 1000;

  .tag-scroll {
    flex: 0 0 auto;
  }
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: #000;
  border-right-width: 1px;
  z-index: 1;
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  border-color: #000;
  box-shadow: none;
  border-right-width: 1px;
  outline: 0;
}
