.home-page {

  h2 {
    font-size: 1.5em;
  }

  section {
    margin: 3em auto;

    &:first-child {
      margin-top: 0;
    }

    &.centered {
      text-align: center;
    }
  }

  form {
    margin-bottom: 1em;

    input {
      outline: none;
      border-bottom: 1px dotted #999;
    }
  }

  span.at-prefix {
    color: black;
    margin-left: 0.4em;
  }
}

tr {
  font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  font-size: 16px !important;
  p, h4 {
    font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
    font-size: 16px !important;
  }

  td {
    font-size: 16px !important;
  }
}
